import '@main/core/bss-connector/js/preloader.js';
import '../scss/index.scss';
import { renderer } from './element-renderer.js';
var render = function (options) {
    renderer.render(options);
};
var css = renderer.css;
export default {
    render: render,
    css: css,
};
