export function gbStep4(_a) {
    var defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'dealerFinderContainer',
            testId: 'dealerFinderContainer',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-3',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 1,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'bss-grid-wrapper--small-padding-bottom',
        },
    ];
}
