/**
 * Created by Marek.Bogdanovic on 08.03.2018.
 * Components included in OA build.
 */
import AbstractComponent from '@main/view/components/bss-abstract-component/js/bss-abstract-component.js';
import AccordionComponent from '@main/view/components/bss-accordion-component/js/bss-accordion-component.js';
import LabelComponent from '@main/view/components/bss-label-component/js/bss-label-component.js';
import TemplateComponent from '@main/view/components/bss-template-component/js/bss-template-component.js';
import TextInputComponent from '@main/view/components/bss-text-input-component/js/bss-text-input-component.js';
import TextInputDateComponent from '@main/view/components/bss-text-input-component/js/bss-text-input-date-component.js';
import CheckboxComponent from '@main/view/components/bss-checkbox-component/js/bss-checkbox-component.js';
import ButtonComponent from '@main/view/components/bss-button-component/js/bss-button-component.js';
import ImageComponent from '@main/view/components/bss-image-component/js/bss-image-component.js';
import StepNavigationComponent from '@main/view/components/bss-step-navigation-component/js/bss-step-navigation-period-component--tabs-dropdown.js';
import RadioButtonComponent from '@main/view/components/bss-radio-button-component/js/bss-radio-button-component.js';
import DropdownComponent from '@main/view/components/bss-dropdown-component/js/bss-dropdown-component.js';
import InfohintComponent from '@main/view/components/bss-infohint-component/js/bss-infohint-component.js';
export var marketComponents = {
    AbstractComponent: AbstractComponent,
    AccordionComponent: AccordionComponent,
    LabelComponent: LabelComponent,
    TemplateComponent: TemplateComponent,
    TextInputComponent: TextInputComponent,
    TextInputDateComponent: TextInputDateComponent,
    CheckboxComponent: CheckboxComponent,
    ImageComponent: ImageComponent,
    ButtonComponent: ButtonComponent,
    StepNavigationComponent: StepNavigationComponent,
    RadioButtonComponent: RadioButtonComponent,
    InfohintComponent: InfohintComponent,
    DropdownComponent: DropdownComponent,
};
