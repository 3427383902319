export function gbStep7(_a) {
    var defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'onlineScoringResult_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            class: 'bss-mt-0',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 1,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: defaultProductId + '.offline',
                    operandRight: 'false',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'approvedScoringResultHeader',
            testId: 'approvedScoringResultHeader',
            componentType: 'LabelComponent',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.approvedScoringResultPageTitle.value'></value>",
            class: 'bss-label--title-big bss-label-step-title',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'approvedScoringResultText',
            testId: 'approvedScoringResultText',
            componentType: 'LabelComponent',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            level: 8,
            order: 2,
            productId: [defaultProductId, 'general'],
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.approvedScoringResultPageText.value'></value>",
            class: 'bss-label--step-navigation-small-title bss-article-wrapper--step-navigation bss-article-wrapper--no-bg bss-mb-2',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'approvedScoringResultProposalText',
            testId: 'approvedScoringResultProposalText',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.approvedScoringResultPageProposalId.value'></value>" +
                "<div><strong><value data-value='" +
                defaultProductId +
                ".internalProposalNumber'></value></strong></div>",
            valueText: true,
            class: 'bss-article-wrapper--step-navigation bss-article-wrapper--no-bg bss-article-wrapper--border-bottom bss-article-proposal bss-mb-5',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'reffereedScoringResultHeader',
            testId: 'reffereedScoringResultHeader',
            componentType: 'LabelComponent',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            level: 8,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.refferedScoringResultPageTitle.value'></value>",
            class: 'bss-label--title-big bss-label-step-title',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'referred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'reffereedScoringResultText',
            testId: 'reffereedScoringResultText',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 5,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.refferedScoringResultPageText.value'></value>",
            valueText: true,
            class: 'bss-label--step-navigation-small-title bss-article-wrapper--step-navigation bss-article-wrapper--no-bg bss-mb-2',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'referred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'refferedScoringResultProposalText',
            testId: 'refferedScoringResultProposalText',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.approvedScoringResultPageProposalId.value'></value>" +
                "<div><strong><value data-value='" +
                defaultProductId +
                ".internalProposalNumber'></value></strong></div>",
            valueText: true,
            class: 'bss-article-wrapper--step-navigation bss-article-wrapper--no-bg bss-article-wrapper--border-bottom bss-article-proposal bss-mb-5',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'referred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'defferedScoringResultHeader',
            testId: 'defferedScoringResultHeader',
            componentType: 'LabelComponent',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            level: 8,
            order: 7,
            productId: [defaultProductId, 'general'],
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.defferedScoringResultPageTitle.value'></value>",
            class: 'bss-label--title-big bss-label-step-title',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'deferred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'defferedScoringResultText',
            testId: 'defferedScoringResultText',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 8,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.defferedScoringResultPageText.value'></value>",
            valueText: true,
            class: 'bss-label--step-navigation-small-title bss-article-wrapper--step-navigation bss-article-wrapper--no-bg bss-mb-2',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'deferred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'defferedScoringResultTextDealerName',
            testId: 'defferedScoringResultTextDealerName',
            containerId: 'onlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 9,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.approvedScoringResultPageProposalId.value'></value>" +
                "<div><strong><value data-value='" +
                defaultProductId +
                ".internalProposalNumber'></value></strong></div>",
            valueText: true,
            class: 'bss-article-wrapper--step-navigation bss-article-wrapper--no-bg bss-article-wrapper--border-bottom bss-article-proposal bss-mb-5',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'deferred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineScoringResult_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 2,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandLeft: defaultProductId + '.offline',
                    operandRight: 'false',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineApprovedScoringResultText',
            testId: 'offlineApprovedScoringResultText',
            componentType: 'LabelComponent',
            containerId: 'offlineScoringResult_' + defaultProductId + '_content',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineApprovedScoringResultPageText.value'></value>",
            class: 'bss-article-wrapper--step-navigation bss-article-wrapper--no-bg',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineReffereedScoringResultText',
            testId: 'offlineReffereedScoringResultText',
            containerId: 'offlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 2,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineReferredScoringResultPageText.value'></value>",
            class: 'bss-article-wrapper--step-navigation bss-article-wrapper--no-bg',
            valueText: true,
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'referred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineDefferedScoringResultText',
            testId: 'offlineDefferedScoringResultText',
            containerId: 'offlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineDeferredScoringResultPageText.value'></value>",
            valueText: true,
            class: 'bss-article-wrapper--step-navigation bss-article-wrapper--no-bg',
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'deferred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineApprovedNextStepContact',
            testId: 'offlineApprovedNextStepContact',
            containerId: 'offlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'itemTitleTextIconTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 4,
            contents: {
                iconType: 'icon-mail',
                title: "<value data-value='" +
                    defaultProductId +
                    ".components.labelComponents.offlineApprovedScoringContactTitle.value'></value>",
                text: "<value data-value='" +
                    defaultProductId +
                    ".components.labelComponents.offlineApprovedScoringContactText.value'></value>",
            },
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineApprovedNextStepDealer',
            testId: 'offlineApprovedNextStepDealer',
            containerId: 'offlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'itemTitleTextIconTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 5,
            contents: {
                iconType: 'icon-dealer',
                title: "<value data-value='" +
                    defaultProductId +
                    ".components.labelComponents.offlineApprovedScoringDealerTitle.value'></value>",
                text: "<value data-value='" +
                    defaultProductId +
                    ".components.labelComponents.offlineApprovedScoringDealerText.value'></value>",
            },
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineApprovedNextStepTestDrive',
            testId: 'offlineApprovedNextStepTestDrive',
            containerId: 'offlineScoringResult_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'itemTitleTextIconTemplate',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 6,
            contents: {
                iconType: 'icon-steering-wheel',
                title: "<value data-value='" +
                    defaultProductId +
                    ".components.labelComponents.offlineApprovedScoringTestDriveTitle.value'></value>",
                text: "<value data-value='" +
                    defaultProductId +
                    ".components.labelComponents.offlineApprovedScoringTestDriveText.value'></value>",
            },
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        // PersonalInformation
        {
            componentId: 'componentPersonalInformation2_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 12,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-component-container--grey',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: defaultProductId + '.proposalStatus',
                    operandRight: ['referred', 'deferred'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'summaryPersonal2_' + defaultProductId,
            containerId: 'componentPersonalInformation2_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 1,
            text: "<value data-text='general.personal_details'></value>",
            class: 'bss-label--title-big bss-label--inline',
        },
        {
            componentId: 'gridPersonalInformation2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentPersonalInformation2_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridPersonalInformation2Label1_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.title.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label2_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.firstName'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.firstName.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label3_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.lastName'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.lastName.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label4_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.phoneNumber'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.phoneNumber.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label5_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.email'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.email.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label6_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-text='general.birthdayDate'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.birthdayDate.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label7_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 7,
            text: "<value data-text='general.gender'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.radioButtonComponents.gender.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label8_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 8,
            text: "<value data-text='general.maritialStatus'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.maritialStatus.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformation2Label9_' + defaultProductId,
            containerId: 'gridPersonalInformation2_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 9,
            text: "<value data-text='general.residentialStatus'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.residentialStatus.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        //Address Detail
        {
            componentId: 'componentAddressDetailInformation2_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 13,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-component-container--grey bss-mt-5 bss-pt-5',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: defaultProductId + '.proposalStatus',
                    operandRight: ['referred', 'deferred'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'summaryAddressDetails2_' + defaultProductId,
            containerId: 'componentAddressDetailInformation2_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 1,
            text: "<value data-text='general.current_address_details'></value>",
            class: 'bss-label--title-big bss-label--inline',
        },
        {
            componentId: 'gridCurrentAddressDetails2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetailInformation2_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails2Label3_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails2Label1_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails2Label2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails2Label4_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails2Label5_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId + '.components_textInputComponents_county_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails2Label6_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_1_occupation_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            class: 'bss-button_btn--show-hidden bss-hide-hidden-address bss-button_btn--secondary bss-button-wrapper--full-width bss-mt-3 bss-mb-3',
            componentId: 'showAllHiddenAddresses2_' + defaultProductId,
            componentType: 'ButtonComponent',
            containerId: 'gridCurrentAddressDetails2_' + defaultProductId + '_col-6',
            isPublicEvent: false,
            level: 10,
            onClickEvent: 'bss-step-navigation-show-hidden-container',
            order: 7,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            symbol: {
                'symbol-before': '&#xe806;',
            },
            text: "<value data-text='general.address_details_show_more'></value>",
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode2_value',
                    operator: 'is',
                },
            ],
        },
        //Address Detail 2
        {
            componentId: 'componentAddressDetail_2_Information_2_cont_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 14,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandLeft: defaultProductId + '.proposalStatus',
                    operandRight: ['approved'],
                    operator: 'indexOf',
                },
            ],
            class: 'showAllHiddenAddresses2 oa-hidden-xs bss-mt-5 bss-pt-5',
        },
        {
            componentId: 'componentAddressDetail_2_Information_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'componentAddressDetail_2_Information_2_cont_' +
                defaultProductId +
                '_content',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode2_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container--grey bss-mt-0',
        },
        {
            componentId: 'summaryAddressDetails_2_2_' + defaultProductId,
            containerId: 'componentAddressDetail_2_Information_2_' +
                defaultProductId +
                '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.previous_address_details'></value>",
            class: 'bss-label--title-big bss-label--inline',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_2_Information_2_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label3_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label1_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label2_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label4_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label5_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label6_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_2_occupation_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        // Address Detail 3
        {
            componentId: 'componentAddressDetail_3_Information_2_cont_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 15,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandLeft: defaultProductId + '.proposalStatus',
                    operandRight: ['approved'],
                    operator: 'indexOf',
                },
            ],
            class: 'showAllHiddenAddresses2 oa-hidden-xs bss-mt-5 bss-pt-5',
        },
        {
            componentId: 'componentAddressDetail_3_Information_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'componentAddressDetail_3_Information_2_cont_' +
                defaultProductId +
                '_content',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode3_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container--grey bss-mt-0',
        },
        {
            componentId: 'summaryAddressDetails_3_2_' + defaultProductId,
            containerId: 'componentAddressDetail_3_Information_2_' +
                defaultProductId +
                '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.previous_address_details'></value>",
            class: 'bss-label--title-big bss-label--inline',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_3_Information_2_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label3_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label1_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.houseNumber'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label2_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode3.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label4_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city3.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label5_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county3.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label6_2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_3_occupation_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        // EmploymentDetails
        {
            componentId: 'componentEmploymentDetails_Information_2_12_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 16,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-mt-5 bss-pt-5',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: defaultProductId + '.proposalStatus',
                    operandRight: ['referred', 'deferred'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'componentEmploymentDetails_Information_2_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'componentEmploymentDetails_Information_2_12_' +
                defaultProductId +
                '_content',
            level: 7,
            order: 16,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container--grey',
        },
        {
            componentId: 'summaryEmploymentDetails_2_2_' + defaultProductId,
            containerId: 'componentEmploymentDetails_Information_2_2_' +
                defaultProductId +
                '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 1,
            text: "<value data-text='general.current_employment_details'></value>",
            class: 'bss-label--title-big bss-label--inline',
        },
        {
            componentId: 'gridEmploymentDetails_2_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentEmploymentDetails_Information_2_2_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12', 'bss-col-xs-12', 'bss-col-xs-12'],
        },
        {
            componentId: 'gridEmploymentDetails_Label-info_2_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'gridEmploymentDetails_2_2_' + defaultProductId + '_col-1',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
            class: 'bss-grid-no-padding',
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'hide',
                    effectFail: 'show',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_Label1_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label2_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.employment_status'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label3_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.occupation'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.occupation.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label4_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.job_title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label5_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.employer'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label6_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress1AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label7_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 7,
            text: "<value data-text='general.employment_postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label8_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 8,
            text: "<value data-text='general.employment_town'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label9_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 9,
            text: "<value data-text='general.employment_county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label10_2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_2_2_' + defaultProductId + '_col-9',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 10,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.employment_1_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            class: 'bss-button_btn--show-hidden bss-hide-hidden-employment bss-button_btn--secondary bss-button-wrapper--full-width bss-mt-3 bss-mb-3',
            componentId: 'showAllHiddenEmploymentDetails2_' + defaultProductId,
            componentType: 'ButtonComponent',
            containerId: 'gridEmploymentDetails_2_2_' + defaultProductId + '_col-2',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-show-hidden-container',
            order: 3,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            symbol: {
                'symbol-before': '&#xe806;',
            },
            text: "<value data-text='general.employment_details_show_more'></value>",
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operator: 'is',
                },
            ],
        },
        //Employment Details 2
        {
            componentId: 'componentEmploymentDetails_Information_2_22_cont_' +
                defaultProductId +
                '',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 17,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandLeft: defaultProductId + '.proposalStatus',
                    operandRight: ['approved'],
                    operator: 'indexOf',
                },
            ],
            class: 'showAllHiddenEmploymentDetails2 oa-hidden-xs bss-mt-5 bss-pt-5',
        },
        {
            componentId: 'componentEmploymentDetails_Information_2_22_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'componentEmploymentDetails_Information_2_22_cont_' +
                defaultProductId +
                '_content',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container--grey',
        },
        {
            componentId: 'summaryEmploymentDetails_2_22_' + defaultProductId,
            containerId: 'componentEmploymentDetails_Information_2_22_' +
                defaultProductId +
                '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.previous_employment_details'></value>",
            class: 'bss-label--title-big bss-label--inline',
        },
        {
            componentId: 'gridEmploymentDetails_2_22_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentEmploymentDetails_Information_2_22_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12', 'bss-col-xs-12'],
        },
        {
            componentId: 'gridEmploymentDetails_2_Label_info_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_22_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'gridEmploymentDetails_2_22_' + defaultProductId + '_col-1',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
            class: 'bss-grid-no-padding',
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'hide',
                    effectFail: 'show',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_2_Label1_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label2_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 2,
            text: "<value data-text='general.employment_status'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label3_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 3,
            text: "<value data-text='general.occupation'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.occupation2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label4_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 4,
            text: "<value data-text='general.job_title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label5_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 5,
            text: "<value data-text='general.employer'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label6_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 6,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress2AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label7_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 7,
            text: "<value data-text='general.employment_postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label8_2' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 8,
            text: "<value data-text='general.employment_town'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label9_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 9,
            text: "<value data-text='general.employment_county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label10_2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_2_' + defaultProductId + '_col-9',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 10,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.employment_2_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        //Bottom footer links
        {
            componentId: 'linksGrid',
            testId: 'linksGrid',
            componentTemplate: 'grid',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 18,
            productId: [defaultProductId, 'general'],
            class: 'bss-mb-5',
            columns: [
                'bss-col-xs-12 bss-col-sm-4',
                'bss-col-xs-12 bss-col-sm-4',
                'bss-col-xs-12 bss-col-sm-4',
            ],
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: defaultProductId + '.proposalStatus',
                    operandRight: ['referred', 'deferred'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'takeACloserLookLink',
            testId: 'takeACloserLookLink',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'linksGrid_col-0',
            level: 7,
            order: 19,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'takeACloserLookLinkEvent',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.scoringResultPageLink1Label.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.scoringResultPageLink1URL.value'></value>",
            target: '_blank',
        },
        {
            componentId: 'arrangeATestDriveLink',
            testId: 'arrangeATestDriveLink',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'linksGrid_col-1',
            level: 7,
            order: 20,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'arrangeATestDriveEvent',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.scoringResultPageLink2Label.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.scoringResultPageLink2URL.value'></value>",
            target: '_blank',
        },
        {
            componentId: 'contactYourDealerLink',
            testId: 'contactYourDealerLink',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'linksGrid_col-2',
            level: 7,
            order: 21,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'contactYourDealerLinkEvent',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.scoringResultPageLink3Label.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.scoringResultPageLink3URL.value'></value>",
            target: '_blank',
        },
        {
            componentId: 'offlineBottomButtons_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-6',
            level: 7,
            order: 22,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-offline-bottom-buttons bss-mb-0',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandLeft: defaultProductId + '.offline',
                    operandRight: 'false',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineApprovedBottomButtons_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12 bss-col-sm-6 bss-mb-2',
                'bss-col-xs-12 bss-col-sm-6 bss-mb-2',
            ],
            containerId: 'offlineBottomButtons_' + defaultProductId + '_content',
            level: 8,
            order: 0,
            productId: [defaultProductId, 'general'],
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'approved',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineApprovedScoringButton1',
            testId: 'offlineApprovedScoringButton1',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'offlineApprovedBottomButtons_' + defaultProductId + '_col-0',
            level: 9,
            order: 0,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'offlineApprovedScoringButton1',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineApprovedScoringButton1Text.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineApprovedScoringButton1Url.value'></value>",
            class: 'bss-link--primary bss-link-wrapper--pulled-left',
            target: '_self',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_offlineApprovedScoringButton1Text_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'offlineApprovedScoringButton2',
            testId: 'offlineApprovedScoringButton2',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'offlineApprovedBottomButtons_' + defaultProductId + '_col-1',
            level: 9,
            order: 1,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'offlineApprovedScoringButton2',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineApprovedScoringButton2Text.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineApprovedScoringButton2Url.value'></value>",
            class: 'bss-link--primary bss-link-wrapper--pulled-right',
            target: '_self',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_offlineApprovedScoringButton2Text_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'offlineDeferredBottomButtons_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12 bss-col-sm-6 bss-mb-2',
                'bss-col-xs-12 bss-col-sm-6 bss-mb-2',
            ],
            containerId: 'offlineBottomButtons_' + defaultProductId + '_content',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'deferred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineDeferredScoringButton1',
            testId: 'offlineDeferredScoringButton1',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'offlineDeferredBottomButtons_' + defaultProductId + '_col-0',
            level: 9,
            order: 0,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'offlineDeferredScoringButton1',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineDeferredScoringButton1Text.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineDeferredScoringButton1Url.value'></value>",
            class: 'bss-link--primary bss-link-wrapper--pulled-left',
            target: '_self',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_offlineDeferredScoringButton1Text_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'offlineDeferredScoringButton2',
            testId: 'offlineDeferredScoringButton2',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'offlineDeferredBottomButtons_' + defaultProductId + '_col-1',
            level: 9,
            order: 1,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'offlineDeferredScoringButton2',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineDeferredScoringButton2Text.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineDeferredScoringButton2Url.value'></value>",
            class: 'bss-link--primary bss-link-wrapper--pulled-right',
            target: '_self',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_offlineDeferredScoringButton2Text_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'offlineReferredBottomButtons_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12 bss-col-sm-6 bss-mb-2',
                'bss-col-xs-12 bss-col-sm-6 bss-mb-2',
            ],
            containerId: 'offlineBottomButtons_' + defaultProductId + '_content',
            level: 8,
            order: 2,
            productId: [defaultProductId, 'general'],
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandLeft: 'referred',
                    operandRight: defaultProductId + '.proposalStatus',
                    operator: 'eq',
                },
            ],
        },
        {
            componentId: 'offlineReferredScoringButton1',
            testId: 'offlineReferredScoringButton1',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'offlineReferredBottomButtons_' + defaultProductId + '_col-0',
            level: 9,
            order: 0,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'offlineReferredScoringButton1',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineReferredScoringButton1Text.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineReferredScoringButton1Url.value'></value>",
            class: 'bss-link--primary bss-link-wrapper--pulled-left',
            target: '_self',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_offlineReferredScoringButton1Text_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'offlineReferredScoringButton2',
            testId: 'offlineReferredScoringButton2',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'offlineReferredBottomButtons_' + defaultProductId + '_col-1',
            level: 9,
            order: 1,
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            onClickEvent: 'offlineReferredScoringButton2',
            sendEventToServer: false,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineReferredScoringButton2Text.value'></value>",
            href: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.offlineReferredScoringButton2Url.value'></value>",
            class: 'bss-link--primary bss-link-wrapper--pulled-right',
            target: '_self',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_offlineReferredScoringButton2Text_value',
                    operator: 'is',
                },
            ],
        },
    ];
}
