import { __spreadArray } from "tslib";
import { gbOverlay } from './ui-overlay';
import { gbRepresentativeExample } from './ui-representative-example';
import { gbStep1 } from './ui-step-1';
import { gbStep2 } from './ui-step-2';
import { gbStep3 } from './ui-step-3';
import { gbStep4 } from './ui-step-4';
import { gbStep5 } from './ui-step-5';
import { gbStep6 } from './ui-step-6';
import { gbStep7 } from './ui-step-7';
export function uiDescription(_a) {
    var _b;
    var flowId = _a.flowId, resultData = _a.resultData, applicationHost = _a.applicationHost, defaultProductId = _a.defaultProductId;
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
        {
            componentId: 'onlineApprovalContainer',
            testId: 'onlineApprovalContainer',
            containerId: 'onlineApproval',
            componentType: 'TemplateComponent',
            componentTemplate: 'sectionTemplate',
            productId: [defaultProductId, 'general'],
            level: 0,
            order: 1,
            'header-content': '',
        },
        {
            componentId: 'navigationTabsOnlineApproval',
            testId: 'navigationTabsOnlineApproval',
            containerId: 'onlineApprovalContainer_content',
            componentType: 'StepNavigationComponent',
            componentTemplate: 'stepsWithDropdown',
            onFormChangeEvent: true,
            fixedNavigationXS: false,
            fixedNavigationSM: false,
            fixedNavigationMD: false,
            rightBoxContainer: true,
            createInExternalBox: true,
            scrollTo: '.bss-step-navigation_trigger-wrapper',
            rightBoxButtonNextId: '#rightBox_button1',
            rightBoxButtonSubmitId: '#rightBox_button2',
            rightBoxButtonSubmitORPId: '#rightBox_button_submit-next',
            rightBoxButtonSubmitReferredORPId: '#rightBox_button_submit-save',
            fixedNavContainerXS: '#navigationTabsOnlineApproval',
            fixedNavContainerSM: '#navigationTabsOnlineApproval',
            fixedNavContainerMD: '#navigationTabsOnlineApproval',
            periodYearsValueClass: 'periodValidationYears',
            periodMonthsValueClass: 'periodValidationMonths',
            communicationCategoriesClass: 'communicationCategories',
            communicationAgreementsClass: 'communicationAgreements',
            buttonsId: [
                'buttonFindAddress_' + defaultProductId + '_btn',
                'buttonFindAddress2_' + defaultProductId + '_btn',
                'buttonFindAddress3_' + defaultProductId + '_btn',
                'buttonEmploymentFindAddress_btn',
                'buttonEmploymentFindAddressPrev_btn',
            ],
            postCodeInputsId: [
                'textInput_PostCode_text-input',
                'textInput_PostCodePrev_text-input',
                'textInput_PostCode3_text-input',
                'textInput_employmentPostCode_text-input',
                'textInput_employmentPostCodePrev_text-input',
            ],
            testInputsId: [
                'textInput_addressLine_1_text-input',
                'textInput_StreetPrev_text-input',
                'textInput_StreetPrev2_text-input',
                'textInput_employmentStreet_text-input',
                'textInput_employmentStreetPrev_text-input',
            ],
            addressDropdownsId: [
                'dropdownAddressData_select',
                'dropdownAddressData2_select',
                'dropdownAddressData3_select',
                'dropdownAddressDataEmployment_select',
                'dropdownAddressDataEmployment2_select',
            ],
            periodParams: {
                '2': {
                    maxYearPeriod: 3,
                    maxPeriodInputAmount: 3,
                },
                '3': {
                    maxYearPeriod: 3,
                    maxPeriodInputAmount: 2,
                },
            },
            dealerFinderSettings: {
                selectorContainer: '#dealerFinderContainer_col-0',
                resultData: {
                    dealerPhoneNumber: 'phone',
                    dealerName: 'name',
                },
            },
            validateNextStep: 'true',
            stepNavigationProgress: false,
            isCheckedInsteadNumber: true,
            eventsForMoving: true,
            level: 1,
            order: 6,
            activeTabId: 0,
            productId: [defaultProductId, 'general'],
            formSelector: 'form',
            action: '',
            method: 'get',
            triggers: [
                {
                    title: "<value data-text='general.personal_details'></value>",
                    tabId: '0',
                    class: 'bss-first-element',
                },
                {
                    title: "<value data-text='general.address_details'></value>",
                    tabId: '1',
                },
                {
                    title: "<value data-text='general.employment_details'></value>",
                    tabId: '2',
                },
                {
                    title: "<value data-text='general.select_your_dealer'></value>",
                    tabId: '3',
                    exclude: true,
                },
                {
                    title: "<value data-text='general.your_privacy'></value>",
                    tabId: '4',
                },
                {
                    title: "<value data-text='general.review_and_sumbit'></value>",
                    tabId: '5',
                },
                {
                    title: "<value data-text='general.confirmation_screen'></value>",
                    tabId: '6',
                    class: 'bss-last-element',
                },
            ],
            isOfflineOA: "<value data-value='" + defaultProductId + ".offline'></value>",
            initOnSwiper: true,
            submitOnPrevStep: true,
            triggersSwiperConfiguration: {
                slidesPerView: 'auto',
                spaceBetween: 16,
                navigation: {
                    nextEl: '.bss-step-navigation_swiper-button-next',
                    prevEl: '.bss-step-navigation_swiper-button-prev',
                    disabledClass: 'bss-step-navigation_swiper-button-disabled',
                },
            },
            mobileNavigationConfig: {
                componentId: 'stepNavigationDropdown',
                testId: 'stepNavigationDropdown',
                containerId: 'navigationTabsOnlineApproval_dropdown-navigation',
                componentType: 'DropdownComponent',
                productId: [defaultProductId, 'general'],
                onChangeOff: true,
                configuration: "<value data-optionliteral='true' data-dropsource='external'></value>",
                symbol: '&#xe804',
                class: 'bss-m-0 bss-dropdown--full-width bss-dropdown--header-step-navigation',
                selectAttributes: {
                    'data-exclude-checkout': true,
                    name: 'stepNavigationDropdown',
                },
                title: "<value data-text='general.dropdown_navigation_title'></value>",
                customSelect: true,
            },
            backToBeginningEvent: 'bss-create-flow-' + flowId + '-entity-1',
            validationSettings: {
                componentId: 'navigationTabsOnlineApproval_validation',
                validation: {
                    additionalHideErrors: '',
                    ajaxValidation: false,
                    defaultErrors: false,
                    formSelector: '#form',
                    throwEventOnError: 'validation/content-changed',
                    throwEventOnSuccess: 'validation/content-changed',
                    url: 'https://jsonplaceholder.typicode.com/posts/1',
                },
            },
            prevButtonSettings: false,
            nextButtonSettings: false,
            submitButtonSettings: false,
            tagEvent: [
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-0',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-0',
                },
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-1',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-1',
                },
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-2',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-2',
                },
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-3',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-3',
                },
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-4',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-4',
                },
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-5',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-5',
                },
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-6',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-6',
                },
                {
                    onEvent: 'publisher',
                    selector: 'bss-step-navigation_navigationTabsOnlineApproval_TabChange-7',
                    throwAfterCalculation: false,
                    throwEvent: 'bss-step-navigation_' + defaultProductId + '_#change-tab-7',
                },
            ],
        },
        {
            componentId: 'closeOnlineApprovalButton',
            testId: 'closeOnlineApprovalButton',
            componentType: 'ButtonComponent',
            containerId: 'navigationTabsOnlineApproval_utils',
            level: 2,
            order: 0,
            onClickEvent: 'bss-create-flow-' + flowId + '-entity-1',
            productId: [defaultProductId, 'general'],
            isPublicEvent: false,
            sendEventToServer: false,
            class: 'bss-button_btn--close',
            text: '',
            symbol: {
                'symbol-before': '&#xE805',
            },
            watcher: [
                {
                    boundDataOperand: 'operandRight',
                    effectSuccess: 'hide',
                    effectFail: 'show',
                    operandLeft: 'onlineApproval',
                    operator: 'eq',
                    operandRight: defaultProductId + '.elementType',
                },
            ],
        }
    ], gbOverlay({ defaultProductId: defaultProductId }), true), gbRepresentativeExample({
        productType: (_b = resultData[defaultProductId]) === null || _b === void 0 ? void 0 : _b.productType,
        defaultProductId: defaultProductId,
    }), true), gbStep1({ defaultProductId: defaultProductId }), true), gbStep2({ applicationHost: applicationHost, defaultProductId: defaultProductId }), true), gbStep3({ applicationHost: applicationHost, defaultProductId: defaultProductId }), true), gbStep4({ defaultProductId: defaultProductId }), true), gbStep5({ defaultProductId: defaultProductId }), true), gbStep6({ defaultProductId: defaultProductId }), true), gbStep7({ defaultProductId: defaultProductId }), true);
}
