import { BSSControllerToolbox } from '@main/core/bss-connector/controller-toolbox';
import { marketComponents } from './components-map';
import { createController } from '@main/core/bss-connector/create-controller.js';
import { uiDescription } from '../ui-description/ui-description';
import { tagging } from './tagging';
var descriptionConfig = {
    onlineApproval: {
        uiDescription: uiDescription,
        tagging: tagging,
    },
};
export var BSSController = createController({
    marketComponents: marketComponents,
    BSSControllerToolbox: BSSControllerToolbox,
    descriptionConfig: descriptionConfig,
});
