export function gbStep6(_a) {
    var defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'summaryGrid',
            testId: 'summaryGrid',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-5',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 1,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12 bss-mb-5',
                'bss-col-xs-12 bss-mb-5 bss-mt-5',
                'bss-col-xs-12 bss-mb-5 bss-mt-5',
                'bss-col-xs-12 bss-mt-3',
            ],
        },
        //Personal Information
        {
            componentId: 'componentPersonalInformation_' + defaultProductId,
            containerId: 'summaryGrid_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 2,
            text: "<value data-text='general.personal_details'></value>",
            class: 'bss-pr-6',
        },
        {
            componentId: 'backToFirstStepViewButton',
            testId: 'backToFirstStepViewButton',
            class: 'bss-button_btn--close bss-button_btn--small-edit',
            componentType: 'ButtonComponent',
            containerId: 'summaryGrid_col-0',
            isPublicEvent: false,
            level: 8,
            onClickEvent: 'bss-step-navigation-goToStep-0-navigationTabsOnlineApproval',
            order: 3,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            text: "<value data-text='general.back_to_edit'></value>",
        },
        {
            componentId: 'gridPersonalInformation_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'summaryGrid_col-0',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 4,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridPersonalInformationLabel1_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.title.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel2_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.firstName'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.firstName.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel3_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.lastName'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.lastName.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel4_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.phoneNumber'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.phoneNumber.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel5_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.email'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.email.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel6_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-text='general.birthdayDate'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.birthdayDate.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel7_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 7,
            text: "<value data-text='general.gender'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.radioButtonComponents.gender.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel8_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 8,
            text: "<value data-text='general.maritialStatus'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.maritialStatus.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridPersonalInformationLabel9_' + defaultProductId,
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 9,
            text: "<value data-text='general.residentialStatus'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.residentialStatus.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        //Address Information
        {
            componentId: 'summaryEmployment_' + defaultProductId,
            containerId: 'summaryGrid_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 5,
            text: "<value data-text='general.current_address_details'></value>",
            class: 'bss-pr-6',
        },
        {
            componentId: 'backToAddressDetailsViewButton_' + defaultProductId,
            testId: 'backToAddressDetailsViewButton',
            class: 'bss-button_btn--close bss-button_btn--small-edit',
            componentType: 'ButtonComponent',
            containerId: 'summaryGrid_col-1',
            isPublicEvent: false,
            level: 8,
            onClickEvent: 'bss-step-navigation-goToStep-1-navigationTabsOnlineApproval',
            order: 6,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            text: "<value data-text='general.back_to_edit'></value>",
        },
        {
            componentId: 'gridCurrentAddressDetails_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'summaryGrid_col-1',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 7,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel1_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel3_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel4_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel5_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId + '.components_textInputComponents_county_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel6_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_1_occupation_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            class: 'bss-button_btn--show-hidden bss-hide-hidden-address bss-button_btn--secondary bss-button-wrapper--full-width bss-mt-3 bss-mb-3',
            componentId: 'showAllHiddenAddresses_' + defaultProductId,
            componentType: 'ButtonComponent',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-6',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-show-hidden-container',
            order: 7,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            symbol: { 'symbol-before': '&#xe806;' },
            text: "<value data-text='general.address_details_show_more'></value>",
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode2_value',
                    operator: 'is',
                },
            ],
        },
        //Address Information 2
        {
            componentId: 'componentAddressDetail_2_Information_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'summaryGrid_col-1',
            level: 8,
            order: 8,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode2_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container--grey showAllHiddenAddresses oa-hidden-xs bss-pt-5 bss-mt-5 bss-mb-0',
        },
        {
            componentId: 'summaryAddressDetails_2_' + defaultProductId,
            containerId: 'componentAddressDetail_2_Information_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.previous_address_details'></value>",
            class: 'bss-pr-6',
        },
        {
            componentId: 'backAddressDetailsViewButton_2_' + defaultProductId,
            testId: 'backAddressDetailsViewButton_2',
            class: 'bss-button_btn--close bss-button_btn--small-edit',
            componentType: 'ButtonComponent',
            containerId: 'componentAddressDetail_2_Information_' + defaultProductId + '_content',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-goToStep-1-navigationTabsOnlineApproval',
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            text: "<value data-text='general.back_to_edit'></value>",
        },
        {
            componentId: 'gridCurrentAddressDetails_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_2_Information_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label1_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label3_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label4_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label5_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label6_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_2_occupation_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        //Address Information 3
        {
            componentId: 'componentAddressDetail_3_Information_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'summaryGrid_col-1',
            level: 8,
            order: 9,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode3_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container--grey showAllHiddenAddresses oa-hidden-xs bss-pt-5 bss-mt-5 bss-mb-0',
        },
        {
            componentId: 'summaryAddressDetails_3_' + defaultProductId,
            containerId: 'componentAddressDetail_3_Information_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.previous_address_details'></value>",
            class: 'bss-pr-6',
        },
        {
            componentId: 'backAddressDetailsViewButton_3_' + defaultProductId,
            class: 'bss-button_btn--close bss-button_btn--small-edit',
            componentType: 'ButtonComponent',
            containerId: 'componentAddressDetail_3_Information_' + defaultProductId + '_content',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-goToStep-1-navigationTabsOnlineApproval',
            text: "<value data-text='general.back_to_edit'></value>",
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
        },
        {
            componentId: 'gridCurrentAddressDetails_3_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_3_Information_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label2_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label1_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label3_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode3.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label4_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city3.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label5_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county3.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label6_' + defaultProductId,
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_3_occupation_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        //Employment Information
        {
            componentId: 'summaryEmploymentDetails_' + defaultProductId,
            containerId: 'summaryGrid_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 10,
            text: "<value data-text='general.current_employment_details'></value>",
            class: 'bss-pr-6',
        },
        {
            componentId: 'backEmploymentDetailsViewButton_' + defaultProductId,
            class: 'bss-button_btn--close bss-button_btn--small-edit',
            componentType: 'ButtonComponent',
            containerId: 'summaryGrid_col-2',
            isPublicEvent: false,
            level: 8,
            onClickEvent: 'bss-step-navigation-goToStep-2-navigationTabsOnlineApproval',
            order: 11,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            text: "<value data-text='general.back_to_edit'></value>",
        },
        {
            componentId: 'gridEmploymentDetails_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'summaryGrid_col-2',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 12,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12', 'bss-col-xs-12', 'bss-col-xs-12'],
        },
        {
            componentId: 'gridEmploymentDetails_Label-info_' + defaultProductId,
            containerId: 'gridEmploymentDetails_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetailsInfo_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'gridEmploymentDetails_' + defaultProductId + '_col-1',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
            class: 'bss-grid-no-padding',
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'hide',
                    effectFail: 'show',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_Label1_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label2_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.employment_status'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label3_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.occupation'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.occupation.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label4_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.job_title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label5_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.employer'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label6_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-text='general.employment_postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label8_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 7,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress1AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label7_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 8,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress1AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label9_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 9,
            text: "<value data-text='general.employment_town'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label10_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-9',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 10,
            text: "<value data-text='general.employment_county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_Label11_' + defaultProductId,
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-10',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 11,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.employment_1_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            class: 'bss-button_btn--show-hidden bss-hide-hidden-employment bss-button_btn--secondary bss-button-wrapper--full-width bss-mt-3 bss-mb-3',
            componentId: 'showAllHiddenEmploymentDetails_' + defaultProductId,
            componentType: 'ButtonComponent',
            containerId: 'gridEmploymentDetails_' + defaultProductId + '_col-2',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-show-hidden-container',
            order: 3,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            symbol: { 'symbol-before': '&#xe806;' },
            text: "<value data-text='general.employment_details_show_more'></value>",
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operator: 'is',
                },
            ],
        },
        //Employment Information 2
        {
            componentId: 'componentEmploymentDetails_Information_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'summaryGrid_col-2',
            level: 8,
            order: 13,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container--grey showAllHiddenEmploymentDetails oa-hidden-xs bss-pt-5 bss-mt-5',
        },
        {
            componentId: 'summaryEmploymentDetails_2_' + defaultProductId,
            containerId: 'componentEmploymentDetails_Information_2_' +
                defaultProductId +
                '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.previous_employment_details'></value>",
            class: 'bss-pr-6',
        },
        {
            componentId: 'backEmploymentDetailsViewButton_2_' + defaultProductId,
            class: 'bss-button_btn--close bss-button_btn--small-edit',
            componentType: 'ButtonComponent',
            containerId: 'componentEmploymentDetails_Information_2_' +
                defaultProductId +
                '_content',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-goToStep-2-navigationTabsOnlineApproval',
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            text: "<value data-text='general.back_to_edit'></value>",
        },
        {
            componentId: 'gridEmploymentDetails_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'componentEmploymentDetails_Information_2_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12', 'bss-col-xs-12'],
        },
        {
            componentId: 'gridEmploymentDetails_2_Label_info_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_2_Info_' + defaultProductId,
            componentType: 'TemplateComponent',
            containerId: 'gridEmploymentDetails_2_' + defaultProductId + '_col-1',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
            class: 'bss-grid-no-padding',
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'hide',
                    effectFail: 'show',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_2_Label1_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label2_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 2,
            text: "<value data-text='general.employment_status'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label3_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 3,
            text: "<value data-text='general.occupation'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.occupation2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label4_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 4,
            text: "<value data-text='general.job_title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label5_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 5,
            text: "<value data-text='general.employer'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label6_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 6,
            text: "<value data-text='general.employment_postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label7_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 7,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress2AddressLine1.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label8_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 8,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress2AddressLine2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label9_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 9,
            text: "<value data-text='general.employment_town'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label10_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-9',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 10,
            text: "<value data-text='general.employment_county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty2.value'></value>",
            class: 'bss-label--text-right bss-label--border-bottom',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label11_' + defaultProductId,
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-10',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 11,
            order: 11,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.employment_2_duration.value'></value>",
            value: '<span></span>',
            class: 'bss-label--text-right bss-label--border-bottom',
        },
    ];
}
