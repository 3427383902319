export function gbOverlay(_a) {
    var defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'overlayContainer_' + defaultProductId,
            testId: 'overlayContainer',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'bssEntityOverlay',
            outsideEntityContainer: '#cc-injection-overlay',
            outerContainerId: '#bssEntityOverlay',
            level: 1,
            order: 0,
            productId: [defaultProductId, 'general'],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'submit',
                    subscriberName: 'bss-step-navigation-OA-rightbox-button-mode',
                },
            ],
        },
        {
            componentId: 'overlayLabel_' + defaultProductId,
            testId: 'overlayLabel',
            containerId: 'bssEntityOverlay',
            outsideEntityContainer: '#cc-injection-overlay',
            outerContainerId: '#bssEntityOverlay',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 1,
            order: 1,
            class: 'bss-label--title-dark bss-label--overlay',
            text: "<value data-text='general.waiting_for_scoring'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'submit',
                    subscriberName: 'bss-step-navigation-OA-rightbox-button-mode',
                },
            ],
        },
        {
            componentId: 'overlayLabelOnSubmitTitle_' + defaultProductId + '_content',
            testId: 'overlayLabelOnSubmitTitle_content',
            containerId: 'overlayContainer_' + defaultProductId,
            outsideEntityContainer: '#cc-injection-overlay',
            outerContainerId: '#overlayContainer_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 1,
            order: 2,
            class: 'bss-label--title-dark bss-label-step-title--padding',
            text: "<value data-text='general.submit_title'></value>",
        },
        {
            componentId: 'overlayLabelOnSubmitText_' + defaultProductId + '_content',
            testId: 'overlayLabelOnSubmitText_content',
            containerId: 'overlayContainer_' + defaultProductId,
            componentType: 'LabelComponent',
            outsideEntityContainer: '#cc-injection-overlay',
            outerContainerId: '#overlayContainer_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 1,
            order: 3,
            class: 'bss-label--title-dark bss-label-step-text bss-label-step-text--padding',
            text: "<value data-text='general.submit_text'></value>",
        },
        {
            componentId: 'overlayLabelOnSubmit_' + defaultProductId,
            testId: 'overlayLabelOnSubmit',
            containerId: 'overlayContainer_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            outsideEntityContainer: '#cc-injection-overlay',
            outerContainerId: '#overlayContainer_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 1,
            order: 4,
            class: 'bss-label--title-dark bss-label--overlay',
            text: "<value data-text='general.waiting_for_submit_response'></value>",
        },
    ];
}
