export function gbStep2(_a) {
    var applicationHost = _a.applicationHost, defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'titleTwoStep',
            testId: 'titleTwoStep',
            containerId: 'navigationTabsOnlineApproval_tab-content-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 1,
            text: "<value data-text='general.address_details'></value>",
        },
        {
            componentId: 'articleTwoStep',
            testId: 'articleTwoStep',
            containerId: 'navigationTabsOnlineApproval_tab-content-1',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 2,
            text: "<value data-text='general.address_information'></value>",
            valueText: true,
            class: 'bss-article-wrapper--no-bg',
        },
        {
            componentId: 'componentContainer_addressDetails',
            testId: 'componentContainer_addressDetails',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            level: 3,
            order: 3,
            containerId: 'navigationTabsOnlineApproval_tab-content-1',
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'visible-online',
        },
        {
            componentId: 'componentContainer_addressDetailsFirstLine',
            testId: 'componentContainer_addressDetailsFirstLine',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            level: 4,
            order: 0,
            containerId: 'componentContainer_addressDetails_content',
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-mt-0 bss-mb-2',
        },
        {
            componentId: 'componentContainer_addressDetailsSecondLine',
            testId: 'componentContainer_addressDetailsSecondLine',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            level: 4,
            order: 1,
            containerId: 'componentContainer_addressDetails_content',
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-mt-0 bss-mb-0',
        },
        {
            componentId: 'componentContainer_addressDetailsThirdLine',
            testId: 'componentContainer_addressDetailsThirdLine',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            level: 4,
            order: 2,
            containerId: 'componentContainer_addressDetails_content',
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-mt-0 bss-mb-0',
        },
        {
            componentId: 'componentContainer_addressDetailsFourthLine',
            testId: 'componentContainer_addressDetailsFourthLine',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            level: 4,
            order: 3,
            containerId: 'componentContainer_addressDetails_content',
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-mt-0 bss-mb-0',
        },
        {
            componentId: 'countryName',
            testId: 'countryName',
            componentType: 'LabelComponent',
            containerId: 'componentContainer_addressDetailsFourthLine_content',
            level: 5,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.united_kingdom'></value>",
            class: 'bss-label--step-navigation-description bss-label-visible-online',
        },
        {
            componentId: 'twoStepFormGrid',
            testId: 'twoStepFormGrid',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-1',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 5,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-mb-3', 'bss-mb-2'],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-mt-2 bss-mb-2 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
            ],
            class: 'bss-grid-wrapper--small-padding-bottom bss-grid-wrapper--address bss-grid-wrapper--with-postcode-lookup',
        },
        {
            componentId: 'textInput_PostCode',
            testId: 'textInput_PostCode',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGrid_row-0_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.postcode'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode.value'></value>",
            required: true,
            class: 'bss-text-input--postcode bss-text-input--required',
            ifDataConvertToLabel: true,
            removeButtonsId: [
                '#twoStepFormGrid_row-0_col-1',
                '#twoStepFormGrid_row-2_col-1',
            ],
            removeHiddenFromId: ['#twoStepFormGrid_row-2_col-0'],
            labelContainerId: 'componentContainer_addressDetailsThirdLine_content',
            labelClass: 'bss-label--step-navigation-description',
            inputAttributes: {
                maxlength: 8,
                'data-rule-required': true,
                'data-error-required': 'postcode-error-required',
                'data-rule-regular': true,
                'data-error-regular': 'postcode-error-invalid',
                'data-regular': '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$',
                name: 'postcode',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'postcode-error-required',
                    text: "<value data-text='general.error_empty_postcode'></value>",
                },
                {
                    id: 'postcode-error-invalid',
                    text: "<value data-text='general.error_invalid_postcode'></value>",
                },
            ],
        },
        {
            componentId: 'buttonFindAddress_' + defaultProductId,
            containerId: 'twoStepFormGrid_row-0_col-1',
            componentType: 'ButtonComponent',
            newDesign: true,
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 3,
            isPublicEvent: true,
            sendEventToServer: false,
            text: "<value data-text='general.find_address'></value>",
            class: 'bss-button_btn--primary bss-button--find-address bss-button_btn--inline bss-button-wrapper--full-width bss-mb-3',
            onClickEvent: 'find-dealer',
            addressLookup: {
                url: 'https://' +
                    applicationHost +
                    '/dispatcher/onlineApproval/addressLookup/gb',
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressData',
                inputDataMap: [
                    {
                        queryName: 'postcode',
                        dataValueName: defaultProductId + '.components.textInputComponents.postcode',
                        required: true,
                    },
                ],
                outputDataMap: [
                    {
                        responseName: 'postcode',
                        dataValueName: defaultProductId + '.components.textInputComponents.postcode',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.address1AddressLine1',
                        responseName: 'address1',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId + '.components.textInputComponents.city',
                        responseName: 'town',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId + '.components.textInputComponents.county',
                        responseName: 'county',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.address1AddressLine2',
                        responseName: 'address2',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.yearsAtAddress',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.monthsAtAddress',
                        editable: true,
                    },
                ],
            },
        },
        {
            componentId: 'manuallyButton',
            testId: 'manuallyButton',
            componentType: 'ButtonComponent',
            containerId: 'twoStepFormGrid_row-0_col-1',
            level: 4,
            order: 4,
            class: 'bss-button_btn--secondary bss-button_btn--inline bss-button-wrapper--full-width bss-mb-3',
            productId: [defaultProductId, 'general'],
            newDesign: true,
            isPublicEvent: false,
            sendEventToServer: false,
            text: "<value data-text='general.manually_enter_your_address'></value>",
            href: '',
            onClickEvent: 'find-dealer',
            focusOnInputId: '#textInput_addressLine_1_text-input',
            addressLookup: {
                manuallyButton: true,
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressData',
                parentId: '#twoStepFormGrid',
                manuallyInputsName: [
                    'address1AddressLine1',
                    'address1AddressLine2',
                    'city',
                    'county',
                ],
            },
        },
        {
            componentId: 'dropdownAddressData',
            testId: 'dropdownAddressData',
            containerId: 'twoStepFormGrid_row-1_col-0',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 4,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.dropdownAddressData'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'addressData-error-required',
                name: 'addressData',
            },
            selectErrors: [
                {
                    id: 'addressData-error-required',
                    text: "<value data-text='general.error_empty_title'></value>",
                },
            ],
            title: "<value data-text='general.address_from_lookup'></value>",
            customSelect: true,
            createCustomSelectOnEvent: true,
        },
        {
            componentId: 'textInput_addressLine_1',
            testId: 'textInput_addressLine_1',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGrid_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 5,
            onChangeOff: true,
            label: "<value data-text='general.address1'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine1.value'></value>",
            required: true,
            ifDataConvertToLabel: true,
            class: 'bss-text-input--required',
            labelContainerId: 'componentContainer_addressDetailsFirstLine_content',
            labelClass: 'bss-label--step-navigation-title bss-inline-block bss-pb-0',
            inputAttributes: {
                'data-rule-required': true,
                'data-error-required': 'street-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'street-error-invalid',
                name: 'address1AddressLine1',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'street-error-required',
                    text: "<value data-text='general.error_empty_street'></value>",
                },
                {
                    id: 'street-error-invalid',
                    text: "<value data-text='general.error_invalid_street'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_addressLine_2',
            testId: 'textInput_addressLine_2',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGrid_row-1_col-2',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 6,
            onChangeOff: true,
            label: "<value data-text='general.address2'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine2.value'></value>",
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_addressDetailsFirstLine_content',
            labelClass: 'bss-label--step-navigation-title bss-inline-block bss-pb-0',
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'address2-error-invalid',
                name: 'address1AddressLine2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'address2-error-invalid',
                    text: "<value data-text='general.error_invalid_houseNumber'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_Town',
            testId: 'textInput_Town',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGrid_row-1_col-3',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 7,
            onChangeOff: true,
            label: "<value data-text='general.city'></value>",
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_addressDetailsSecondLine_content',
            labelClass: 'bss-label--step-navigation-description',
            class: 'bss-text-input--required',
            required: true,
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city.value'></value>",
            inputAttributes: {
                maxlength: 40,
                name: 'city',
                'data-rule-required': true,
                'data-error-required': 'city-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'city-error-invalid',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'city-error-required',
                    text: "<value data-text='general.error_empty_city'></value>",
                },
                {
                    id: 'city-error-invalid',
                    text: "<value data-text='general.error_invalid_city'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_Country',
            testId: 'textInput_Country',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGrid_row-1_col-4',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 8,
            onChangeOff: true,
            label: "<value data-text='general.county'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county.value'></value>",
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'county-error-invalid',
                maxlength: 40,
                name: 'county',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'county-error-invalid',
                    text: "<value data-text='general.error_invalid_county'></value>",
                },
            ],
        },
        {
            componentId: 'LongLiveAddressGrid',
            testId: 'LongLiveAddressGrid',
            componentType: 'TemplateComponent',
            containerId: 'twoStepFormGrid_row-2_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 9,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0', 'bss-mb-3', 'bss-mb-3'],
                },
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0', 'bss-mt-0'],
                },
            ],
            class: 'bss-grid-wrapper--small-padding-bottom',
        },
        {
            componentId: 'titleForLongLiveAddress',
            testId: 'titleForLongLiveAddress',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGrid_row-0_col-0',
            level: 5,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.atAddressTitle'></value>",
            class: 'bss-label--step-navigation-description bss-label--required bss-label--required-icon',
        },
        {
            componentId: 'textInput_Years',
            testId: 'textInput_Years',
            class: 'periodValidationYears-2 bss-text-input-no-required-sign',
            componentType: 'TextInputComponent',
            containerId: 'LongLiveAddressGrid_row-0_col-1',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 1,
            onChangeOff: true,
            label: "<value data-text='general.yearsAtAddress'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.yearsAtAddress.value'></value>",
            inputAttributes: {
                maxlength: 2,
                name: 'yearsAtAddress',
                type: 'text',
                'data-regular': '^[0-9]*$',
            },
            maskSettings: '9{0,2}',
        },
        {
            componentId: 'textInput_Months',
            testId: 'textInput_Months',
            class: 'periodValidationMonths-2 bss-text-input-no-required-sign',
            componentType: 'TextInputComponent',
            containerId: 'LongLiveAddressGrid_row-0_col-2',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.monthsAtAddress'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.monthsAtAddress.value'></value>",
            inputAttributes: {
                maxlength: 2,
                'data-regular': '(^$)|^([0-9]|10|11)$',
                'data-regular-max-value': 11,
                'data-rule-regular': true,
                'data-error-regular': 'monthsAtAddress-error-invalid',
                name: 'monthsAtAddress',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'monthsAtAddress-error-invalid',
                    text: "<value data-text='general.error_invalid_monthsAtAddress'></value>",
                },
            ],
            maskSettings: '9{0,2}',
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide bss-error-message-external bss-p-0 bss-m-0',
            componentId: 'periodValidationErrorLabel',
            testId: 'periodValidationErrorLabel',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGrid_row-0_col-2',
            level: 5,
            order: 3,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_invalid_yearsAtAddress'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-zero-value-error-1-2',
                },
            ],
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide bss-error-message-external bss-p-0 bss-m-0',
            componentId: 'periodValidationErrorLabelEmpty',
            testId: 'periodValidationErrorLabelEmpty',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGrid_row-0_col-2',
            level: 5,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_empty_yearsAtAddress'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-empty-value-error-1-2',
                },
            ],
        },
        // Prev Address
        {
            componentId: 'twoStepFormGridPrevAddress',
            testId: 'twoStepFormGridPrevAddress',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-1',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 6,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0 bss-mb-2', 'bss-mb-3', 'bss-mb-2'],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-mt-2 bss-mb-2 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-clickOnNextButton-1-tab-2',
                },
            ],
            class: 'bss-watcher-hide bss-grid-wrapper--address bss-grid-wrapper--with-postcode-lookup',
        },
        {
            componentId: 'periodValidationLabel',
            testId: 'periodValidationLabel',
            componentType: 'LabelComponent',
            containerId: 'twoStepFormGridPrevAddress_row-0_col-0',
            level: 4,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.previous_address_tilte'></value>",
            hintText: "<value data-text='general.address_history_description'></value>",
            class: 'bss-label--step-navigation-description',
        },
        {
            componentId: 'textInput_PostCodePrev',
            testId: 'textInput_PostCodePrev',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridPrevAddress_row-0_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.postcode'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode2.value'></value>",
            required: true,
            class: 'bss-text-input--postcode bss-text-input--required',
            inputAttributes: {
                maxlength: 8,
                'data-rule-required': true,
                'data-error-required': 'postcode2-error-required',
                'data-rule-regular': true,
                'data-error-regular': 'postcode2-error-invalid',
                'data-regular': '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$',
                name: 'postcode2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'postcode2-error-required',
                    text: "<value data-text='general.error_empty_postcode2'></value>",
                },
                {
                    id: 'postcode2-error-invalid',
                    text: "<value data-text='general.error_invalid_postcode2'></value>",
                },
            ],
        },
        {
            componentId: 'buttonFindAddress2_' + defaultProductId,
            containerId: 'twoStepFormGridPrevAddress_row-0_col-2',
            componentType: 'ButtonComponent',
            newDesign: true,
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 3,
            isPublicEvent: true,
            sendEventToServer: false,
            text: "<value data-text='general.find_address'></value>",
            class: 'bss-button_btn--primary bss-button--find-address bss-button_btn--inline bss-button-wrapper--full-width bss-mb-3',
            onClickEvent: 'find-dealer',
            addressLookup: {
                url: 'https://' +
                    applicationHost +
                    '/dispatcher/onlineApproval/addressLookup/gb',
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressData2',
                inputDataMap: [
                    {
                        queryName: 'postcode',
                        dataValueName: defaultProductId + '.components.textInputComponents.postcode2',
                        required: true,
                    },
                ],
                outputDataMap: [
                    {
                        responseName: 'postcode',
                        dataValueName: defaultProductId + '.components.textInputComponents.postcode2',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.address2AddressLine1',
                        responseName: 'address1',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId + '.components.textInputComponents.city2',
                        responseName: 'town',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId + '.components.textInputComponents.county2',
                        responseName: 'county',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.address2AddressLine2',
                        responseName: 'address2',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.yearsAtAddress2',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.monthsAtAddress2',
                        editable: true,
                    },
                ],
            },
        },
        {
            componentId: 'manuallyButtonPrev',
            testId: 'manuallyButtonPrev',
            componentType: 'ButtonComponent',
            containerId: 'twoStepFormGridPrevAddress_row-0_col-2',
            level: 4,
            order: 4,
            class: 'bss-button_btn--secondary bss-button_btn--inline bss-button-wrapper--full-width bss-mb-3',
            productId: [defaultProductId, 'general'],
            newDesign: true,
            isPublicEvent: false,
            sendEventToServer: false,
            text: "<value data-text='general.manually_enter_your_address'></value>",
            href: '',
            onClickEvent: 'find-dealer',
            focusOnInputId: '#textInput_StreetPrev_text-input',
            addressLookup: {
                manuallyButton: true,
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressData2',
                parentId: '#twoStepFormGridPrevAddress',
                manuallyInputsName: [
                    'address2AddressLine1',
                    'address2AddressLine2',
                    'city2',
                    'county2',
                ],
            },
        },
        {
            componentId: 'dropdownAddressData2',
            testId: 'dropdownAddressData2',
            containerId: 'twoStepFormGridPrevAddress_row-1_col-0',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 4,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.dropdownAddressData2'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'addressData2-error-required',
                name: 'addressData2',
            },
            selectErrors: [
                {
                    id: 'addressData2-error-required',
                    text: "<value data-text='general.error_empty_title'></value>",
                },
            ],
            title: "<value data-text='general.address_from_lookup'></value>",
            customSelect: true,
            createCustomSelectOnEvent: true,
        },
        {
            componentId: 'textInput_StreetPrev',
            testId: 'textInput_StreetPrev',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 5,
            onChangeOff: true,
            label: "<value data-text='general.address1'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine1.value'></value>",
            required: true,
            class: 'bss-text-input--required bss-mb-3',
            inputAttributes: {
                'data-rule-required': true,
                'data-error-required': 'street-error-required-prev',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'street-error-invalid-prev',
                name: 'address2AddressLine1',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'street-error-required-prev',
                    text: "<value data-text='general.error_empty_street'></value>",
                },
                {
                    id: 'street-error-invalid-prev',
                    text: "<value data-text='general.error_invalid_street2'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_HousePrev',
            testId: 'textInput_HousePrev',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 6,
            onChangeOff: true,
            label: "<value data-text='general.address2'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine2.value'></value>",
            class: 'bss-mb-3',
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'house-error-invalid-prev',
                name: 'address2AddressLine2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'house-error-invalid-prev',
                    text: "<value data-text='general.error_invalid_houseNumber2'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_TownPrev',
            testId: 'textInput_TownPrev',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 7,
            onChangeOff: true,
            label: "<value data-text='general.city'></value>",
            required: true,
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city2.value'></value>",
            inputAttributes: {
                maxlength: 40,
                name: 'city2',
                'data-rule-required': true,
                'data-error-required': 'city2-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'city2-error-invalid',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'city2-error-required',
                    text: "<value data-text='general.error_empty_city2'></value>",
                },
                {
                    id: 'city2-error-invalid',
                    text: "<value data-text='general.error_invalid_city2'></value>",
                },
            ],
            class: 'bss-text-input--required bss-mb-3',
        },
        {
            componentId: 'textInput_CountryPrev',
            testId: 'textInput_CountryPrev',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 8,
            onChangeOff: true,
            label: "<value data-text='general.county'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county2.value'></value>",
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'country2-error-invalid',
                maxlength: 40,
                name: 'county2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'country2-error-invalid',
                    text: "<value data-text='general.error_invalid_county2'></value>",
                },
            ],
            class: 'bss-mb-3',
        },
        {
            componentId: 'countryNamePostLookupPrev',
            testId: 'countryNamePostLookupPrev',
            componentType: 'LabelComponent',
            containerId: 'twoStepFormGridPrevAddress_row-1_col-1',
            level: 4,
            order: 9,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.united_kingdom'></value>",
            class: 'bss-label--step-navigation-description',
        },
        {
            componentId: 'LongLiveAddressGridPrev',
            testId: 'LongLiveAddressGridPrev',
            componentType: 'TemplateComponent',
            containerId: 'twoStepFormGridPrevAddress_row-2_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 11,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0', 'bss-mb-3', 'bss-mb-3'],
                },
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0', 'bss-mt-0'],
                },
            ],
            class: 'bss-grid-wrapper--small-padding-bottom',
        },
        {
            componentId: 'titleForLongLiveAddressPrev',
            testId: 'titleForLongLiveAddressPrev',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGridPrev_row-0_col-0',
            level: 5,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.atAddressTitle'></value>",
            class: 'bss-label--step-navigation-description bss-label--required bss-label--required-icon',
        },
        {
            componentId: 'textInput_YearsPrev',
            testId: 'textInput_YearsPrev',
            class: 'periodValidationYears-2 bss-text-input-no-required-sign',
            componentType: 'TextInputComponent',
            containerId: 'LongLiveAddressGridPrev_row-0_col-1',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 1,
            onChangeOff: true,
            label: "<value data-text='general.yearsAtAddress'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.yearsAtAddress2.value'></value>",
            inputAttributes: {
                maxlength: 2,
                name: 'yearsAtAddress2',
                type: 'text',
                'data-regular': '^[0-9]*$',
            },
            maskSettings: '9{0,2}',
        },
        {
            componentId: 'textInput_MonthsPrev',
            testId: 'textInput_MonthsPrev',
            class: 'periodValidationMonths-2 bss-text-input-no-required-sign',
            componentType: 'TextInputComponent',
            containerId: 'LongLiveAddressGridPrev_row-0_col-2',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.monthsAtAddress'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.monthsAtAddress2.value'></value>",
            inputAttributes: {
                maxlength: 2,
                'data-regular': '(^$)|^([0-9]|10|11)$',
                'data-regular-max-value': 11,
                'data-rule-regular': true,
                'data-error-regular': 'monthsAtAddress2-error-invalid',
                name: 'monthsAtAddress2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'monthsAtAddress2-error-invalid',
                    text: "<value data-text='general.error_invalid_monthsAtAddress2'></value>",
                },
            ],
            maskSettings: '9{0,2}',
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide bss-error-message-external bss-p-0 bss-m-0',
            componentId: 'periodValidationErrorLabelPrev',
            testId: 'periodValidationErrorLabelPrev',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGridPrev_row-0_col-2',
            level: 5,
            order: 3,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_invalid_yearsAtAddress'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-zero-value-error-2-2',
                },
            ],
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide bss-error-message-external bss-p-0 bss-m-0',
            componentId: 'periodValidationErrorLabelEmpty2',
            testId: 'periodValidationErrorLabelEmpty2',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGridPrev_row-0_col-2',
            level: 5,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_empty_yearsAtAddress'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-empty-value-error-2-2',
                },
            ],
        },
        // Prev Address 2
        {
            componentId: 'twoStepFormGridSecondPrevAddress',
            testId: 'twoStepFormGridSecondPrevAddress',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-1',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 7,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0 bss-mb-2', 'bss-mb-3', 'bss-mb-2'],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-mb-3 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-mt-2 bss-mb-2 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-clickOnNextButton-2-tab-2',
                },
            ],
            class: 'bss-watcher-hide bss-grid-wrapper--padding-bottom bss-grid-wrapper--with-border-bottom bss-grid-wrapper--address bss-grid-wrapper--with-postcode-lookup',
        },
        {
            componentId: 'periodValidationLabel3',
            testId: 'periodValidationLabel3',
            componentType: 'LabelComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-0_col-0',
            level: 4,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.previous_address_tilte'></value>",
            hintText: "<value data-text='general.address_history_description'></value>",
            class: 'bss-label--step-navigation-description',
        },
        {
            componentId: 'textInput_PostCode3',
            testId: 'textInput_PostCode3',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-0_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.postcode'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode3.value'></value>",
            required: true,
            class: 'bss-text-input--postcode bss-text-input--required',
            inputAttributes: {
                maxlength: 8,
                'data-rule-required': true,
                'data-error-required': 'postcode3-error-required',
                'data-rule-regular': true,
                'data-error-regular': 'postcode3-error-invalid',
                'data-regular': '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$',
                name: 'postcode3',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'postcode3-error-required',
                    text: "<value data-text='general.error_empty_postcode3'></value>",
                },
                {
                    id: 'postcode3-error-invalid',
                    text: "<value data-text='general.error_invalid_postcode3'></value>",
                },
            ],
        },
        {
            componentId: 'buttonFindAddress3_' + defaultProductId,
            containerId: 'twoStepFormGridSecondPrevAddress_row-0_col-2',
            componentType: 'ButtonComponent',
            newDesign: true,
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 3,
            isPublicEvent: true,
            sendEventToServer: false,
            text: "<value data-text='general.find_address'></value>",
            class: 'bss-button_btn--primary bss-button--find-address bss-button_btn--inline bss-button-wrapper--full-width bss-mb-3',
            onClickEvent: 'find-dealer',
            addressLookup: {
                url: 'https://' +
                    applicationHost +
                    '/dispatcher/onlineApproval/addressLookup/gb',
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressData3',
                inputDataMap: [
                    {
                        queryName: 'postcode',
                        dataValueName: defaultProductId + '.components.textInputComponents.postcode3',
                        required: true,
                    },
                ],
                outputDataMap: [
                    {
                        responseName: 'postcode',
                        dataValueName: defaultProductId + '.components.textInputComponents.postcode3',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.address3AddressLine1',
                        responseName: 'address1',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId + '.components.textInputComponents.city3',
                        responseName: 'town',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId + '.components.textInputComponents.county3',
                        responseName: 'county',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.address3AddressLine2',
                        responseName: 'address2',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.yearsAtAddress3',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.monthsAtAddress3',
                        editable: true,
                    },
                ],
            },
        },
        {
            componentId: 'manuallyButton3',
            testId: 'manuallyButton3',
            componentType: 'ButtonComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-0_col-2',
            level: 4,
            order: 4,
            class: 'bss-button_btn--secondary bss-button_btn--inline bss-button-wrapper--full-width bss-mb-3',
            productId: [defaultProductId, 'general'],
            newDesign: true,
            isPublicEvent: false,
            sendEventToServer: false,
            text: "<value data-text='general.manually_enter_your_address'></value>",
            href: '',
            onClickEvent: 'find-dealer',
            focusOnInputId: '#textInput_StreetPrev2_text-input',
            addressLookup: {
                manuallyButton: true,
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressData3',
                parentId: '#twoStepFormGridSecondPrevAddress',
                manuallyInputsName: [
                    'address3AddressLine1',
                    'address3AddressLine2',
                    'city3',
                    'county3',
                ],
            },
        },
        {
            componentId: 'dropdownAddressData3',
            testId: 'dropdownAddressData3',
            containerId: 'twoStepFormGridSecondPrevAddress_row-1_col-0',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 4,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.dropdownAddressData3'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'addressData-error-required',
                name: 'addressData3',
            },
            selectErrors: [
                {
                    id: 'addressData3-error-required',
                    text: "<value data-text='general.error_empty_title'></value>",
                },
            ],
            title: "<value data-text='general.address_from_lookup'></value>",
            customSelect: true,
            createCustomSelectOnEvent: true,
        },
        {
            componentId: 'textInput_StreetPrev2',
            testId: 'textInput_StreetPrev2',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 4,
            onChangeOff: true,
            label: "<value data-text='general.address1'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine1.value'></value>",
            required: true,
            class: 'bss-text-input--required bss-mb-3',
            inputAttributes: {
                'data-rule-required': true,
                'data-error-required': 'street-error-required-prev-2',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'street-error-invalid-prev-2',
                name: 'address3AddressLine1',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'street-error-required-prev-2',
                    text: "<value data-text='general.error_empty_street'></value>",
                },
                {
                    id: 'street-error-invalid-prev-2',
                    text: "<value data-text='general.error_invalid_street3'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_HousePrev2',
            testId: 'textInput_HousePrev2',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 6,
            onChangeOff: true,
            label: "<value data-text='general.address2'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine2.value'></value>",
            class: 'bss-mb-3',
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'house-error-invalid-prev-2',
                name: 'address3AddressLine2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'house-error-invalid-prev-2',
                    text: "<value data-text='general.error_invalid_houseNumber3'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_TownPrev2',
            testId: 'textInput_TownPrev2',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 7,
            onChangeOff: true,
            label: "<value data-text='general.city'></value>",
            required: true,
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city3.value'></value>",
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'city3-error-invalid',
                maxlength: 40,
                name: 'city3',
                'data-rule-required': true,
                'data-error-required': 'city3-error-required',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'city3-error-required',
                    text: "<value data-text='general.error_empty_city3'></value>",
                },
                {
                    id: 'city3-error-invalid',
                    text: "<value data-text='general.error_invalid_city3'></value>",
                },
            ],
            class: 'bss-text-input--required bss-mb-3',
        },
        {
            componentId: 'textInput_CountryPrev2',
            testId: 'textInput_CountryPrev2',
            componentType: 'TextInputComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 8,
            onChangeOff: true,
            label: "<value data-text='general.county'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county3.value'></value>",
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'country3-error-invalid',
                maxlength: 40,
                name: 'county3',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'country3-error-invalid',
                    text: "<value data-text='general.error_invalid_county3'></value>",
                },
            ],
            class: 'bss-mb-3',
        },
        {
            componentId: 'countryNameSecondPrev',
            testId: 'countryNameSecondPrev',
            componentType: 'LabelComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-1_col-1',
            level: 4,
            order: 9,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.united_kingdom'></value>",
            class: 'bss-label--step-navigation-description',
        },
        {
            componentId: 'LongLiveAddressGridPrev2',
            testId: 'LongLiveAddressGridPrev2',
            componentType: 'TemplateComponent',
            containerId: 'twoStepFormGridSecondPrevAddress_row-2_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 11,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0', 'bss-mb-3', 'bss-mb-3'],
                },
                {
                    class: 'oa-form-row',
                    columns: ['bss-mt-0', 'bss-mt-0'],
                },
            ],
            class: 'bss-grid-wrapper--small-padding-bottom',
        },
        {
            componentId: 'titleForLongLiveAddressPrev2',
            testId: 'titleForLongLiveAddressPrev2',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGridPrev2_row-0_col-0',
            level: 5,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.atAddressTitle'></value>",
            class: 'bss-label--step-navigation-description bss-label--required bss-label--required-icon',
        },
        {
            componentId: 'textInput_YearsPrev2',
            testId: 'textInput_YearsPrev2',
            class: 'periodValidationYears-2 bss-text-input-no-required-sign',
            componentType: 'TextInputComponent',
            containerId: 'LongLiveAddressGridPrev2_row-0_col-1',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 1,
            onChangeOff: true,
            label: "<value data-text='general.yearsAtAddress'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.yearsAtAddress3.value'></value>",
            inputAttributes: {
                maxlength: 2,
                name: 'yearsAtAddress3',
                type: 'text',
                'data-regular': '^[0-9]*$',
            },
            maskSettings: '9{0,2}',
        },
        {
            componentId: 'textInput_MonthsPrev2',
            testId: 'textInput_MonthsPrev2',
            class: 'periodValidationMonths-2 bss-text-input-no-required-sign',
            componentType: 'TextInputComponent',
            containerId: 'LongLiveAddressGridPrev2_row-0_col-2',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.monthsAtAddress'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.monthsAtAddress3.value'></value>",
            inputAttributes: {
                maxlength: 2,
                'data-regular': '(^$)|^([0-9]|10|11)$',
                'data-regular-max-value': 11,
                'data-rule-regular': true,
                'data-error-regular': 'monthsAtAddress3-error-invalid',
                name: 'monthsAtAddress3',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'monthsAtAddress3-error-invalid',
                    text: "<value data-text='general.error_invalid_monthsAtAddress3'></value>",
                },
            ],
            maskSettings: '9{0,2}',
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide bss-error-message-external bss-p-0 bss-m-0',
            componentId: 'periodValidationErrorLabelPrev2',
            testId: 'periodValidationErrorLabelPrev2',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGridPrev2_row-0_col-2',
            level: 5,
            order: 3,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_invalid_yearsAtAddress'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-zero-value-error-3-2',
                },
            ],
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide bss-error-message-external bss-p-0 bss-m-0',
            componentId: 'periodValidationErrorLabelEmpty3',
            testId: 'periodValidationErrorLabelEmpty3',
            componentType: 'LabelComponent',
            containerId: 'LongLiveAddressGridPrev2_row-0_col-2',
            level: 5,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_empty_yearsAtAddress'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-empty-value-error-3-2',
                },
            ],
        },
    ];
}
